import * as React from "react"
import App from '../components/app/App'
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';

// markup
const IndexPage = () => {
  return <Layout pageInfo={{ pageName: "index" }}>
    <Seo title="Home" />
    <App />
  </Layout>
}

export default IndexPage
