import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import image from '../../images/idea.jpeg'
import './Action.scss'

const Action = () => {
  return (
    <Container id="action">
      <Row>
        <Col md={7}>
          <h6 className="component-title-secondary">Let's Talk</h6>
          <h2 className="component-title">Free Consultation</h2>
          <p className="pb-3 px-5">
            During your 30-minute free consultation, we will offer our opinion
            on your project's value proposition, understand your long term
            vision and identify how we can help you on your journey. Please book
            an appointment using the Calendly link and we will be more than
            happy to talk to you. Our team carefully analyzes your business
            processes and needs with you, to identify the most appropriate
            blockchain solutions for your goals.
          </p>
          <Button href="https://calendly.com/defitect/30min" target="_blank" className="mx-5">Book now</Button>
        </Col>
        <Col md={5}>
          <Image fluid src={image}></Image>
        </Col>
      </Row>
    </Container>
  )
}

export default Action
