import React from 'react'
import { Col, Container, Row, Image, Stack } from 'react-bootstrap'
import image from '../../images/about2.png'
import './About.scss'
import FiBotLogo from '../../images/FiBot-Icon-Black.svg'
import HPAYLogo from '../../images/HPay-Logo.png'

const About = () => {
  return (
    <Container id="about" className="section-margin">
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={7} className="px-0">
          <Image fluid className="about-image" src={image}></Image>
        </Col>
        <Col md={5} xs={10} className="about-container px-5">
          <h6 className="component-title-secondary">About us</h6>
          <h2 className="component-title">Who are we?</h2>
          <p className="px-5">
            Defitect is a blockchain development, consulting and auditing
            company. Our vision follows three main tenants: efficiency, security
            and usability. We aim to provide quality and trustworthy services to
            anybody that is looking imprvove the blockchain space by bringing
            their ideas to reality.
          </p>
          <Row className="d-flex justify-content-between align-items-start mt-5">
            <h6 className="component-title-secondary mb-4"> Our Partners</h6>
            <Stack direction="horizontal" gap={5} className="px-5">
              <img src={FiBotLogo}
                onMouseOver={e => (e.currentTarget.src = HPAYLogo)}
                onMouseOut={e => (e.currentTarget.src = FiBotLogo)}
                className="partener-logo" alt="FiBot"></img>
              <img
                onMouseOver={e => (e.currentTarget.src = FiBotLogo)}
                onMouseOut={e => (e.currentTarget.src = HPAYLogo)}

                src={HPAYLogo}
                className="partener-logo"
                alt="HedgePay"
              ></img>
              <img src={FiBotLogo}
                onMouseOver={e => (e.currentTarget.src = HPAYLogo)}
                onMouseOut={e => (e.currentTarget.src = FiBotLogo)}
                className="partener-logo" alt="FiBot"></img>
            </Stack>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default About
