import './App.scss'
import React from 'react'
import Contact from '../contact/Contact'
import Services from '../services/Services'
import Home from '../header/Home'
import About from '../about/About'
import Action from '../action/Action'

function App() {
  return (
    <div className="App">
      <Home />
      <Services />
      <About />
      <Action />
      <Contact />
    </div>
  )
}

export default App
