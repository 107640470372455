import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import './Home.scss'

const Home = () => {
  return (
    <Container fluid className="background-image main-content">
      <Container id="home" className="d-flex main-content">
        <Row className="align-items-center mx-0 my-5 py-5">
          <Col md={6} className="header-text">
            <h1 className="pb-4">
              Smart contracts development, auditing, and consulting
            </h1>
            <h6 className="pb-5">
              Custom solutions for building your decentralised applications.
            </h6>
            <Button href="https://calendly.com/defitect/30min" target="_blank" className="header-button learn-more">
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span className="button-text">Book a free call</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Home
